import React from "react";
import { Form, Input, Radio, Button } from "antd";
import logo from "./assets/logo.png"; // Update with the correct path to your image file
import "./App.css";
import { useNavigate } from "react-router-dom";

const FormCompo = () => {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm(); // Create a form instance
  const navigate = useNavigate(); // Hook for navigat
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BK_URL}/api/submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      form.resetFields(); // Reset form fields
      navigate("/thank-you");
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App min-h-screen flex items-center justify-center py-2 pb-10 bg-gray-100">
      <header className="w-full lg:w-[50%] p-8 border border-orange-500 rounded-md bg-white shadow-lg">
        {/* Logo with shadow */}
        <div className="flex justify-center mb-6">
          <img src={logo} alt="LIGMR Logo" className="w-24 h-auto" />
        </div>

        {/* Description */}
        <div className="mb-8 text-center">
          <p className="text-xl font-semibold font-medium text-gray-700 mb-2">
            Study in France - Free Webinar
          </p>
          <p className="text-lg font-semibold text-gray-600">
            Learn about the possibilities for your future in France by experts.
          </p>
        </div>

        {/* Heading */}
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-6">
          Register Now
        </h1>

        {/* Form */}
        <Form
          form={form} // Pass the form instance
          name="contact_form"
          onFinish={onFinish}
          layout="vertical"
          className="space-y-6"
        >
          <Form.Item
            name="name"
            label={<span className="text-lg font-medium">Name</span>}
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input
              placeholder="Enter your name"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400 transition duration-200 hover:border-orange-400"
            />
          </Form.Item>

          <Form.Item
            name="number"
            label={<span className="text-lg font-medium">Number</span>}
            rules={[
              { required: true, message: "Please enter your number" },
              { pattern: /^\d+$/, message: "Please enter a valid number" },
            ]}
          >
            <Input
              placeholder="Enter your number"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400 transition duration-200 hover:border-orange-400"
            />
          </Form.Item>

          <Form.Item
            name="email"
            label={<span className="text-lg font-medium">Email</span>}
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input
              placeholder="Enter your email"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400 transition duration-200 hover:border-orange-400"
            />
          </Form.Item>

          <Form.Item
            name="country"
            label={<span className="text-lg font-medium">Country</span>}
            rules={[{ required: true, message: "Please enter your country" }]}
          >
            <Input
              placeholder="Enter your country"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400 transition duration-200 hover:border-orange-400"
            />
          </Form.Item>

          <Form.Item
            name="question"
            label={<span className="text-lg font-medium">Any Question</span>}
          >
            <Input.TextArea
              placeholder="Do you have any questions?"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400 transition duration-200 hover:border-orange-400"
            />
          </Form.Item>

          <Form.Item
            name="role"
            label={
              <span className="text-lg font-medium">
                How would you like to connect with us?
              </span>
            }
            rules={[{ required: true, message: "Please select an option" }]}
          >
            <Radio.Group className="space-x-6">
              <Radio value="student" className="text-lg text-gray-700">
                Student
              </Radio>
              <Radio value="partner" className="text-lg text-gray-700">
                Partner
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full text-xl bg-orange-500 text-white py-5 rounded-lg shadow-lg transition-colors duration-300"
              style={{ backgroundColor: "#F97316", borderColor: "#F97316" }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#EA580C")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#F97316")
              }
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </header>
    </div>
  );
};

export default FormCompo;
