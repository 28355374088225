import React from "react";
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FormCompo from "./FormCompo";
import ThankYouPage from "./Thankyou";

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<FormCompo />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
      </Routes>
    </Router>
  );
}

export default App;
