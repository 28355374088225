import React from "react";
import { FaCheckCircle, FaRegClock } from 'react-icons/fa'; // Import icons from react-icons

const ThankYouPage = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="p-8 bg-white w-[90%] md:w-[60%] lg:w-[40%] border border-orange-500 rounded-md shadow-lg text-center space-y-3">
                {/* Icons */}
                <div className="text-orange-500 flex justify-center text-6xl mb-4">
                    <FaCheckCircle />
                </div>

                {/* Main Message */}
                <h1 className="text-4xl font-bold text-gray-900 mb-4">
                    Thank You!
                </h1>

                {/* Sub-message */}
                <p className="text-xl text-gray-600">
                    Your submission was successful. We appreciate your interest.
                </p>
                <p className="text-xl text-gray-600">
                    For Further Updates Join Out Channel. {" "}
                    <a href="https://bit.ly/JoinLIGMR" className="text-blue-500">
                        Join
                    </a>
                </p>


                {/* Additional Info */}
                <p className="text-lg text-gray-500 flex items-center justify-center">
                    <FaRegClock className="text-gray-400 mr-2" />
                    We will get back to you shortly with more information.
                </p>
            </div>
        </div>
    );
}

export default ThankYouPage;
